/*!
 * Swiper
 * use : Swiper(CDN)
 */

document.addEventListener('DOMContentLoaded', () => {
  new Swiper("[swiper-top] .swiper-container", {
    loop: false,
    slidesPerView: "auto",
    centeredSlides: false,
    freeMode: true,
    spaceBetween: 10,
    breakpoints: {
      768: {
        freeMode: false,
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });

  new Swiper("[swiper-work-detail] .swiper-container", {
    direction: "vertical",
    slidesPerView: 1,
    spaceBetween: 30,
    mousewheel: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: false,
    },
  })
});

