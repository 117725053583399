/*!
 * Header menu open
 */
document.addEventListener('DOMContentLoaded', () => {
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
  const activeClass = "is-active";
  const clippingClass = "is-clipped";

  if ($navbarBurgers.length > 0) {
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {
        const target = el.dataset.target;
        const $target = document.getElementById(target);
        // active class toggle
        el.classList.toggle(activeClass);
        $target.classList.toggle(activeClass);
        // overflow clipping
        document.querySelector('html').classList.toggle(clippingClass);
        document.body.classList.toggle(clippingClass);
      });
    });
  }
});