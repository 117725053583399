/*!
 * Image loading
 * Use fallback script : https://github.com/aFarkas/lazysizes
 */
document.addEventListener('DOMContentLoaded', () => {
  const images = document.querySelectorAll('img[loading="lazy"]');
  const img_srcset = document.querySelectorAll("img[data-srcset]");
  const sources = document.querySelectorAll("source[data-srcset]");

  if ("loading" in HTMLImageElement.prototype) {
    // src
    images.forEach((img) => {
      img.src = img.dataset.src;
    });
    // data-src
    img_srcset.forEach((img_srcset) => {
      img_srcset.srcset = img_srcset.dataset.srcset;
    });
    //picture
    sources.forEach((source) => {
      source.srcset = source.dataset.srcset;
    });
  } else {
    // fallback
    images.forEach((img) => {
      img.classList.add("lazyload");
    });
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.0/lazysizes.min.js";
    document.body.appendChild(script);
  }
});